import useAuth from '../hooks/useAuth'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Modal from './Modal'
import GenerateQuestion from './GenerateQuestion'

function ActiveQuestionnaire({
  questionnaireData,
  clientReference,
  downloadable,
  resetQ,
  session,
  setList,
  list,
  update,
}) {
  const [questionnaireResponse, setQuestionnaireResponse] = useState({})
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [isFormSave, setIsFormSave] = useState(false)
  const { setShowLoader, createInfo } = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const { clientId, claimId } = useParams()

  const axios = useAxiosPrivate()

  let hasChanged = false

  useEffect(() => {
    setQuestionnaireResponse(questionnaireData.content)
  }, [questionnaireData])

  const cleanupPDF = async (url) => {
    try {
      const response = await axios.post(`/pdf/cleanup`, { url: url })
      if (response.data.error) {
        console.log(response.data.error.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleMouseLeave = () => {
    // if (isFormSave === true) {
    //   unsavedChangesClicked()
    // }
  }

  const unloadPage = () => {
    if (hasChanged) {
      const answer = window.confirm(
        'You have unsaved changes on this page. Click OK to leave this page and discard your changes or Cancel to stay on this page?'
      )
      if (answer) {
        resetQ()
      } else {
        return
      }
    }
    resetQ()
  }

  const saveQuestionnaire = async (e) => {
    setShowLoader(true)
    setIsFormSave(false)
    e.preventDefault()
    const data = {
      session_id: session.id,
      content: questionnaireResponse,
      clientId: clientId,
      claimId: claimId,
    }

    let url = 'store'
    if (update === true) {
      url = `update/${questionnaireData.id}`
    }

    try {
      const response = await axios.post(`/questionnaire-response/${url}`, data)
      if (!response.data.error) {
        if (!update) {
          setList([...list, response.data.result])
        } else {
          setList(response.data.result)
        }
        createInfo(
          'success',
          `Questionnaire '${questionnaireResponse.title}' saved`
        )
        resetQ()
      }
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const downloadQuestionnaire = async () => {
    try {
      setShowLoader(true)

      const response = await axios
        .post(`/pdf/download`, {
          id: questionnaireData.id,
          clientReference: clientReference,
        })
        .then((response) => {
          window.open(response.data)
          setShowLoader(false)
          cleanupPDF(response.data)
        })
    } catch (error) {
      console.log(error)
      // Error handling
    }
  }

  const unsavedChangesClicked = () => {
    setModalTitle(`You have unsaved changes`)
    setUnsavedChanges(true)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (unsavedChanges) {
      return <UnsavedElement />
    }
  }

  const UnsavedElement = () => {
    const saveChanges = async (e) => {
      saveQuestionnaire(e)
    }

    return (
      <form onSubmit={saveChanges}>
        <div className="mb-3">
          You have unsaved changes. Please save your changes before leaving the
          page.
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Save</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={() => {
              resetModal()
            }}
          >
            Continue Editing
          </button>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setShowModal(false)
  }

  return (
    <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <form onSubmit={saveQuestionnaire}>
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="md:w-1/2">
            <h3 className="font-bold text-lg mb-2">
              {questionnaireResponse?.title}
            </h3>
          </div>
          <div className="md:w-1/2 flex justify-end">
            <button className="btn green mr-4">Save Questionnaire</button>
            {downloadable && (
              <button
                className="btn blue mr-4"
                type="button"
                onClick={() => {
                  downloadQuestionnaire()
                }}
              >
                Download Questionnaire
              </button>
            )}
            <button
              className="btn gray"
              onClick={(e) => {
                e.preventDefault()
                unloadPage()
                // resetQ()
              }}
            >
              Close
            </button>
          </div>
        </div>
        <div>
          {questionnaireResponse?.questions?.map((item, index) => {
            return (
              <GenerateQuestion
                data={item}
                index={index}
                setIsFormSave={setIsFormSave}
                questionnaireResponse={questionnaireResponse}
                hasChanged={hasChanged}
                setQuestionnaireResponse={setQuestionnaireResponse}
              />
            )
          })}
        </div>
        <div className="w-full flex justify-between">
          <h2>Total: {questionnaireResponse?.total}</h2>
          <div className="flex justify-end">
            <button className="btn green mr-4">Save Questionnaire</button>
            {downloadable && (
              <button
                className="btn blue mr-4"
                type="button"
                onClick={() => {
                  downloadQuestionnaire()
                }}
              >
                Download Questionnaire
              </button>
            )}
            <button
              className="btn gray"
              onClick={(e) => {
                e.preventDefault()
                resetQ()
              }}
            >
              Close
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default ActiveQuestionnaire
