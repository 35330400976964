import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SystemNotification({ notificationList, clearNotification }) {
  let notifications = notificationList

  return (
    <section className="max-h-96 overflow-y-auto">
      {notifications.length > 0 ? (
        notifications.map((notification) => {
          return (
            <article
              key={notification.id}
              title="Click to mark as read"
              className={`article-item notification-list cursor-pointer hover:border-slate-600 ${notification.type.toLowerCase()}`}
              onClick={() => clearNotification(notification)}
            >
              <span className="absolute top-1 right-2 text-xs text-gray-400">
                <FontAwesomeIcon icon={faEyeSlash} />
              </span>
              <h3 className="text-lg font-semibold leading-5">
                {notification.type}: {notification.title}
              </h3>
              <hr className="mt-2" />
              <p className="text-md mt-2">{notification.body}</p>
            </article>
          )
        })
      ) : (
        <h2 className="mb-2">There are no notifications.</h2>
      )}
    </section>
  )
}

export default SystemNotification
