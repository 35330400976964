/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MainTitle from '../../components/MainTitle'
import { Navigate, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClientNav from '../../components/ClientNav'
import moment from 'moment'
import { faPenToSquare, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PhoneNumberUtil } from 'google-libphonenumber'
import AutoComplete from 'react-google-autocomplete'
import { Switch } from '@headlessui/react'

const GOOGLE_API_KEY = 'AIzaSyDcTd3xEx_Y4ZlRo-7eeoSWzLUnWO67LFU'

const Client = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [editMode, setEditMode] = useState(false)
  const [editClientData, setEditClientData] = useState({})
  const [editClientDetails, setEditClientDetails] = useState({})
  const [editClientAddress, setEditClientAddress] = useState({})
  const [editGpDetails, setEditGpDetails] = useState({})
  const [editGPAddress, setEditGPAddress] = useState({})
  const [editChildDetails, setEditChildDetails] = useState({})
  const [editEmergencyContact, setEditEmergencyContact] = useState({})
  const [editEmergencyContactAddress, setEditEmergencyContactAddress] =
    useState({})
  const [editContactMethod, setEditContactMethod] = useState([])
  const [contactMethods, setContactMethods] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isChild, setIsChild] = useState(false)
  const [enabled, setEnabled] = useState(true)
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })
  const { setShowLoader } = useAuth()
  const [clientFound, setClientFound] = useState(true)
  const isMobile = window.innerWidth < 768

  const getContactMethods = async () => {
    try {
      const response = await axios.get(`/contact-methods/all`)
      setContactMethods(response.data.result)
    } catch (error) {}
  }

  const getClientById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/clients/${id}`)
    
      console.log(response.data.error);
      if (!response.data.error) { 
        await setClientData(response.data.result)

        if (response.data.result.child === 1) {
          setIsChild(true)
        }
  
        const contactMethodsArray = Object.values(
          response.data.result.contact_methods
        )
        setEditContactMethod(contactMethodsArray)
        setClientFound(true)
      }  else {
        setClientFound(false)
      }
      setShowLoader(false)
    } catch (error) {
      setClientFound(false)
    }
  }

  useEffect(() => {
    getClientById(id)
    getContactMethods()
  }, [id])

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target
    setPhoneNumber(value)
    const isValidNumber = validatePhoneNumber(value)
    setIsValid(isValidNumber)
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance()
    try {
      const parsedNumber = phoneNumberUtil.parse(phoneNumber, '+44')
      const isValidNumber = phoneNumberUtil.isValidNumber(parsedNumber)
      return isValidNumber
    } catch (e) {
      console.error('Error parsing phone number:', e)
      return false
    }
  }

  const handleInputChange = (fieldName, e) => {
    setEditClientData({ ...editClientData, [fieldName]: e.target.value })
  }

  const handleDetailsChange = (fieldName, e) => {
    setEditClientDetails({ ...editClientDetails, [fieldName]: e.target.value })
  }

  const handleClientPlaceSelected = (place) => {
    const addressFormatted = place.formatted_address
    const segments = place.address_components
    let data = {}
    segments.forEach((key) => {
      const type = key.types[0]
      data[type] = key.long_name
    })

    const formattedSegments = addressFormatted.split(', ')

    const address = {
      address_line_1: data['street_number']
        ? data['street_number'] + ' ' + data['route']
        : formattedSegments[1],
      address_line_2: data['locality'] ?? data['political'],
      city: data['postal_town'],
      county: data['administrative_area_level_2'],
      country: data['country'],
      postcode: data['postal_code'],
    }

    setEditClientAddress(address)
    updateClientAddress(address)
  }

  const updateClientAddress = (address) => {
    let addressString = ''

    Object.keys(address).forEach((key, index) => {
      if (key === 'value') {
        return
      }

      if (!address[key] || address[key].trim() === '') {
        return
      }

      addressString += address[key]

      if (index !== Object.keys(address).length - 1) {
        addressString += ', '
      }
    })

    setEditClientDetails({
      ...editClientDetails,
      ['home_address']: addressString,
    })
  }

  const handleGPPlaceSelected = (place) => {
    const addressFormatted = place.formatted_address
    const segments = place.address_components
    let data = {}
    segments.forEach((key) => {
      const type = key.types[0]
      data[type] = key.long_name
    })

    const formattedSegments = addressFormatted.split(', ')
    const address = {
      name: '',
      address_line_0: data['premise'],
      address_line_1: data['street_number']
        ? data['street_number'] + ' ' + data['route']
        : formattedSegments[1],
      address_line_2: data['locality'] ?? data['political'],
      city: data['postal_town'],
      county: data['administrative_area_level_2'],
      country: data['country'],
      postcode: data['postal_code'],
    }

    setEditGPAddress(address)
    updateGPAddress(address)
  }

  const updateGPAddress = (address) => {
    let addressString = ''

    Object.keys(address).forEach((key, index) => {
      if (key === 'value') {
        return
      }

      if (!address[key] || address[key].trim() === '') {
        return
      }

      addressString += address[key]

      if (index !== Object.keys(address).length - 1) {
        addressString += ', '
      }
    })

    setEditGpDetails({ ...editGpDetails, ['address']: addressString })
  }

  const handleEmergencyContactPlaceSelected = (place) => {
    const addressFormatted = place.formatted_address
    const segments = place.address_components
    let data = {}
    segments.forEach((key) => {
      const type = key.types[0]
      data[type] = key.long_name
    })

    const formattedSegments = addressFormatted.split(', ')

    const address = {
      address_line_1: data['street_number']
        ? data['street_number'] + ' ' + data['route']
        : formattedSegments[1],
      address_line_2: data['locality'] ?? data['political'],
      city: data['postal_town'],
      county: data['administrative_area_level_2'],
      country: data['country'],
      postcode: data['postal_code'],
    }

    setEditEmergencyContactAddress(address)
    updateEmergencyContactAddress(address)
  }

  const updateEmergencyContactAddress = (address) => {
    let addressString = ''

    Object.keys(address).forEach((key, index) => {
      if (key === 'value') {
        return
      }

      if (!address[key] || address[key].trim() === '') {
        return
      }

      addressString += address[key]

      if (index !== Object.keys(address).length - 1) {
        addressString += ', '
      }
    })

    setEditEmergencyContact({
      ...editEmergencyContact,
      ['address']: addressString,
    })
  }

  const handleGpDetailsChange = (fieldName, e) => {
    setEditGpDetails({ ...editGpDetails, [fieldName]: e.target.value })
  }
  const handleChildDetailsChange = (fieldName, e) => {
    setEditChildDetails({ ...editChildDetails, [fieldName]: e.target.value })
  }

  const handleEmergencyContactChange = (fieldName, e) => {
    setEditEmergencyContact({
      ...editEmergencyContact,
      [fieldName]: e.target.value,
    })
  }

  const handleContactMethod = (method) => {
    const updatedContactMethods = [...editContactMethod]
    const index = updatedContactMethods.findIndex(
      (contactMethod) => contactMethod.id === method.id
    )

    if (index !== -1) {
      updatedContactMethods.splice(index, 1)
    } else {
      updatedContactMethods.push(method)
    }

    setEditContactMethod(updatedContactMethods)
  }

  const handleEditClick = () => {
    setEditMode((prevState) => !prevState)
    setEditClientData({ ...clientData })
    setEditClientDetails({ ...clientData.client_details })
    setEditGpDetails({ ...JSON.parse(clientData.client_details.gp_details) })
    setEditChildDetails({
      ...JSON.parse(clientData.client_details.child_details),
    })
    setEditEmergencyContact({
      ...JSON.parse(clientData.client_details.emergency_contact),
    })
    setEditContactMethod([...clientData.contact_methods])
    setIsValid(true)

    setEditEmergencyContactAddress({})
    setEditGPAddress({})
    setEditClientAddress({})
  }

  useEffect(() => {
    if (clientData?.client_details?.phone_number) {
      setPhoneNumber(clientData.client_details?.phone_number)
    }
    if(clientData?.client_details){
      setEnabled(clientData.client_details.send_notifications === 1 );
    }
  }, [clientData])

  const handleSaveClick = async () => {
    let data = editClientData
    data.client_details = editClientDetails
    data.client_details.gp_details = editGpDetails
    data.client_details.child_details = editChildDetails
    data.client_details.emergency_contact = editEmergencyContact
    data.send_notifications = enabled

    const contactMethodIds = editContactMethod.map((method) => method.id)
    data.contact_method_ids = contactMethodIds

    try {
      setShowLoader(true)
      const response = await axios.post(`/clients/${id}/edit-details`, data)
      setClientData(response.data.result)
      setEditMode(false)
    } catch (error) {
      console.error(error)
    }
    setShowLoader(false)
  }

  if (!clientFound) {
    return <Navigate to="/404" replace />;
  }

  const { address_line_0 = '', address_line_1 = '' } = editGPAddress;

  return (
    <div>
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        client={clientData}
        backButton
        child={isChild}
        discharge
        waiting
      />
      <ClientNav active="details" client={clientData} audit={clientData.id} type={'Client'} />

      {clientData.id && (
        <section>
          <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
            <h2 className="font-bold text-xl mb-2">Important Information</h2>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Reference:
              </span>
              {clientData.reference}
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Current Risk Level:
              </span>
              <span
                className="status"
                style={{
                  backgroundColor: clientData.risk[0]
                    ? clientData.risk[0].color
                    : 'gray',
                }}
              >
                {clientData.risk[0] ? clientData.risk[0].title : 'N/A'}
              </span>
            </div>

            <hr className="mt-4 pb-4" />
            {editMode && (
              <div className="py-3 px-4 bg-teal-200 rounded-lg mb-4 text-sm">
                <span className="font-medium">You are in Editing Mode.</span>{' '}
                Click the pen icon to return to viewing or save to update
                details.{' '}
              </div>
            )}
            <div className="flex flex-col md:flex-row items-center justify-between">
              <h2 className="font-bold text-xl mb-2">Client Details</h2>
              <div>
                {!isMobile && (
                  <FontAwesomeIcon
                    title="Edit Client"
                    onClick={() => setEditMode((prev) => !prev)}
                    icon={faPenToSquare}
                    className={`text-xl hover:text-blue-500 cursor-pointer ${editMode ? 'text-blue-500' : ''}`}
                  />
                )}
              </div>
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Full Name:
              </span>
              {editMode ? (
                <input
                  type="text"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                  defaultValue={editClientData.name}
                  onChange={(e) => handleInputChange('name', e)}
                />
              ) : (
                <span>{clientData.name}</span>
              )}
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Email Address:
              </span>
              {editMode ? (
                <input
                  type="text"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                  defaultValue={editClientData.email}
                  onChange={(e) => handleInputChange('email', e)}
                />
              ) : (
                <span>{clientData.email}</span>
              )}
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Main Contact Number:
              </span>
              <div>
                {editMode ? (
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={phoneNumber}
                    onChange={(e) => {
                      handlePhoneNumberChange(e)
                      handleDetailsChange('phone_number', e)
                    }}
                  />
                ) : (
                  <span>{clientData.client_details?.phone_number}</span>
                )}
                <div>
                  {editMode && (
                    <div className="text-left">
                      {phoneNumber.length > 0 && (
                        <>
                          {isValid ? (
                            <span className="text-green-500 text-sm">
                              Valid phone number
                            </span>
                          ) : (
                            <span className="text-red-600 text-sm">
                              Invalid phone number
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Date of Birth:
              </span>
              {editMode ? (
                <input
                  type="date"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                  defaultValue={editClientDetails?.date_of_birth}
                  onChange={(e) => handleDetailsChange('date_of_birth', e)}
                />
              ) : (
                <span>
                  {' '}
                  {moment(clientData.client_details?.date_of_birth).format(
                    'DD/MM/YYYY'
                  )}
                </span>
              )}
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Home Address:
              </span>
              {editMode ? (
                <AutoComplete
                  defaultValue={editClientDetails?.home_address}
                  type="text"
                  name="client_address"
                  required
                  id="client_address"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm min-w-[400px]"
                  apiKey={GOOGLE_API_KEY}
                  onPlaceSelected={(place) => handleClientPlaceSelected(place)}
                  options={{
                    types: [],
                    componentRestrictions: { country: 'gb' },
                  }}
                />
              ) : (
                <span>{clientData.client_details?.home_address}</span>
              )}
            </div>
            {editMode && editClientAddress.address_line_1 && (
              <div className="ml-10 my-4">
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Address Line 1:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editClientAddress.address_line_1 || ''}
                    onChange={(e) => {
                      const address = editClientAddress
                      address.address_line_1 = e.target.value
                      setEditClientAddress({
                        ...editClientAddress,
                        address_line_1: e.target.value,
                      })
                      updateClientAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Address Line 2:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editClientAddress.address_line_2 || ''}
                    onChange={(e) => {
                      const address = editClientAddress
                      address.address_line_2 = e.target.value
                      setEditClientAddress({
                        ...editClientAddress,
                        address_line_2: e.target.value,
                      })
                      updateClientAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    City:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editClientAddress.city || ''}
                    onChange={(e) => {
                      const address = editClientAddress
                      address.city = e.target.value
                      setEditClientAddress({
                        ...editClientAddress,
                        city: e.target.value,
                      })
                      updateClientAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    County:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editClientAddress.county || ''}
                    onChange={(e) => {
                      const address = editClientAddress
                      address.county = e.target.value
                      setEditClientAddress({
                        ...editClientAddress,
                        county: e.target.value,
                      })
                      updateClientAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Country:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editClientAddress.country || ''}
                    onChange={(e) => {
                      const address = editClientAddress
                      address.country = e.target.value
                      setEditClientAddress({
                        ...editClientAddress,
                        country: e.target.value,
                      })
                      updateClientAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Postcode:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editClientAddress.postcode || ''}
                    onChange={(e) => {
                      const address = editClientAddress
                      address.postcode = e.target.value
                      setEditClientAddress({
                        ...editClientAddress,
                        postcode: e.target.value,
                      })
                      updateClientAddress(address)
                    }}
                  />
                </div>
              </div>
            )}
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Assigned Region:
              </span>
              <span>{clientData.client_details?.region}</span>
            </div>

            <hr className="mt-4 pb-4" />

            <div>
                <h2 className="font-bold text-xl mb-2">Additional Information</h2>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Claim Reference:
                  </span>
                  {clientData.claim_reference}
                </div>
              </div>

            <hr className="mt-4 pb-4" />
            {!isChild ? (
              <div>
                <h2 className="font-bold text-xl mb-2">GP Details</h2>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Name:
                  </span>
                  {editMode ? (
                    <input
                      type="text"
                      className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                      defaultValue={editGpDetails.name}
                      onChange={(e) => handleGpDetailsChange('name', e)}
                    />
                  ) : (
                    <span>
                      {JSON.parse(clientData.client_details?.gp_details).name}
                    </span>
                  )}
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Address:
                  </span>
                  {editMode ? (
                    <AutoComplete
                      defaultValue={editGpDetails.address}
                      type="text"
                      name="gp_address"
                      required
                      id="gp_address"
                      className="border border-slate-400 rounded-lg px-2 py-1 text-sm min-w-[400px]"
                      apiKey={GOOGLE_API_KEY}
                      onPlaceSelected={(place) => handleGPPlaceSelected(place)}
                      options={{
                        types: [],
                        componentRestrictions: { country: 'gb' },
                      }}
                    />
                  ) : (
                    <span>
                      {JSON.parse(clientData.client_details?.gp_details).address}
                    </span>
                  )}
                </div>
                {editMode && editGPAddress.address_line_1 && (
                  <div className="ml-10 my-4">
                    <div className="mb-2 flex flex-col md:flex-row">
                      <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                        Surgery Name:
                      </span>
                      <input
                        type="text"
                        className="border border-slate-400 rounded-lg px-2 py-1 text-sm w-1/5"
                        value={editGPAddress.name || ''}
                        onChange={(e) => {
                          const address = editGPAddress
                          address.name = e.target.value
                          setEditGPAddress({
                            ...editGPAddress,
                            name: e.target.value,
                          })
                          updateGPAddress(address)
                        }}
                      />
                    </div>
                    <div className="mb-2 flex flex-col md:flex-row">
                      <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                        Address Line 1:
                      </span>
                      <input
                        type="text"
                        className="border border-slate-400 rounded-lg px-2 py-1 text-sm w-1/5"
                        value={`${address_line_0}${address_line_1 ? ', ' + address_line_1 : ''}`}
                        onChange={(e) => {
                          const address = editGPAddress
                          address.address_line_1 = e.target.value
                          setEditGPAddress({
                            ...editGPAddress,
                            address_line_1: e.target.value,
                          })
                          updateGPAddress(address)
                        }}
                      />
                    </div>
                    <div className="mb-2 flex flex-col md:flex-row">
                      <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                        Address Line 2:
                      </span>
                      <input
                        type="text"
                        className="border border-slate-400 rounded-lg px-2 py-1 text-sm w-1/5"
                        value={editGPAddress.address_line_2 || ''}
                        onChange={(e) => {
                          const address = editGPAddress
                          address.address_line_2 = e.target.value
                          setEditGPAddress({
                            ...editGPAddress,
                            address_line_2: e.target.value,
                          })
                          updateGPAddress(address)
                        }}
                      />
                    </div>
                    <div className="mb-2 flex flex-col md:flex-row">
                      <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                        City:
                      </span>
                      <input
                        type="text"
                        className="border border-slate-400 rounded-lg px-2 py-1 text-sm w-1/5"
                        value={editGPAddress.city || ''}
                        onChange={(e) => {
                          const address = editGPAddress
                          address.city = e.target.value
                          setEditGPAddress({
                            ...editGPAddress,
                            city: e.target.value,
                          })
                          updateGPAddress(address)
                        }}
                      />
                    </div>
                    <div className="mb-2 flex flex-col md:flex-row">
                      <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                        County:
                      </span>
                      <input
                        type="text"
                        className="border border-slate-400 rounded-lg px-2 py-1 text-sm w-1/5"
                        value={editGPAddress.county || ''}
                        onChange={(e) => {
                          const address = editGPAddress
                          address.county = e.target.value
                          setEditGPAddress({
                            ...editGPAddress,
                            county: e.target.value,
                          })
                          updateGPAddress(address)
                        }}
                      />
                    </div>
                    <div className="mb-2 flex flex-col md:flex-row">
                      <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                        Country:
                      </span>
                      <input
                        type="text"
                        className="border border-slate-400 rounded-lg px-2 py-1 text-sm w-1/5"
                        value={editGPAddress.country || ''}
                        onChange={(e) => {
                          const address = editGPAddress
                          address.country = e.target.value
                          setEditGPAddress({
                            ...editGPAddress,
                            country: e.target.value,
                          })
                          updateGPAddress(address)
                        }}
                      />
                    </div>
                    <div className="mb-2 flex flex-col md:flex-row">
                      <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                        Postcode:
                      </span>
                      <input
                        type="text"
                        className="border border-slate-400 rounded-lg px-2 py-1 text-sm w-1/5"
                        value={editGPAddress.postcode || ''}
                        onChange={(e) => {
                          const address = editGPAddress
                          address.postcdoe = e.target.value
                          setEditGPAddress({
                            ...editGPAddress,
                            postcdoe: e.target.value,
                          })
                          updateGPAddress(address)
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Contact Number:
                  </span>
                  {editMode ? (
                    <input
                      type="text"
                      className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                      defaultValue={editGpDetails.contact_number}
                      onChange={(e) =>
                        handleGpDetailsChange('contact_number', e)
                      }
                    />
                  ) : (
                    <span>
                      {
                        JSON.parse(clientData.client_details?.gp_details)
                          .contact_number
                      }
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <h2 className="font-bold text-xl mb-2">
                  Safeguard Contact Details
                </h2>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Lead Name:
                  </span>
                  {editMode ? (
                    <input
                      type="text"
                      className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                      defaultValue={editChildDetails.name}
                      onChange={(e) => handleChildDetailsChange('name', e)}
                    />
                  ) : (
                    <span>
                      {JSON.parse(clientData.client_details.child_details)
                        .name ?? ''}
                    </span>
                  )}
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Email:
                  </span>
                  {editMode ? (
                    <input
                      type="text"
                      className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                      defaultValue={editChildDetails.email}
                      onChange={(e) => handleChildDetailsChange('email', e)}
                    />
                  ) : (
                    <span>
                      {JSON.parse(clientData.client_details.child_details)
                        .email ?? ''}
                    </span>
                  )}
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Contact Number:
                  </span>
                  {editMode ? (
                    <input
                      type="text"
                      className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                      defaultValue={editChildDetails.contact_number}
                      onChange={(e) =>
                        handleChildDetailsChange('contact_number', e)
                      }
                    />
                  ) : (
                    <span>
                      {JSON.parse(clientData.client_details.child_details)
                        .contact_number ?? ''}
                    </span>
                  )}
                </div>
              </div>
            )}
            <hr className="mt-4 pb-4" />
            <h2 className="font-bold text-xl mb-2">Emergency Contact</h2>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Name:
              </span>
              {editMode ? (
                <input
                  type="text"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                  defaultValue={editEmergencyContact.name}
                  onChange={(e) => handleEmergencyContactChange('name', e)}
                />
              ) : (
                <span>
                  {JSON.parse(clientData.client_details.emergency_contact).name}
                </span>
              )}
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Address:
              </span>
              {editMode ? (
                <AutoComplete
                  defaultValue={editEmergencyContact.address}
                  type="text"
                  name="emergency_address"
                  required
                  id="emergency_address"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm min-w-[400px]"
                  apiKey={GOOGLE_API_KEY}
                  onPlaceSelected={(place) =>
                    handleEmergencyContactPlaceSelected(place)
                  }
                  options={{
                    types: [],
                    componentRestrictions: { country: 'gb' },
                  }}
                />
              ) : (
                <span>
                  {
                    JSON.parse(clientData.client_details.emergency_contact)
                      .address
                  }
                </span>
              )}
            </div>

            {editMode && editEmergencyContactAddress.address_line_1 && (
              <div className="ml-10 my-4">
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Address Line 1:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editEmergencyContactAddress.address_line_1 || ''}
                    onChange={(e) => {
                      const address = editEmergencyContactAddress
                      address.address_line_1 = e.target.value
                      setEditEmergencyContactAddress({
                        ...editEmergencyContactAddress,
                        address_line_1: e.target.value,
                      })
                      updateEmergencyContactAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Address Line 2:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editEmergencyContactAddress.address_line_2 || ''}
                    onChange={(e) => {
                      const address = editEmergencyContactAddress
                      address.address_line_2 = e.target.value
                      setEditEmergencyContactAddress({
                        ...editEmergencyContactAddress,
                        address_line_2: e.target.value,
                      })
                      updateEmergencyContactAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    City:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editEmergencyContactAddress.city || ''}
                    onChange={(e) => {
                      const address = editEmergencyContactAddress
                      address.city = e.target.value
                      setEditEmergencyContactAddress({
                        ...editEmergencyContactAddress,
                        city: e.target.value,
                      })
                      updateEmergencyContactAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    County:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editEmergencyContactAddress.county || ''}
                    onChange={(e) => {
                      const address = editEmergencyContactAddress
                      address.county = e.target.value
                      setEditEmergencyContactAddress({
                        ...editEmergencyContactAddress,
                        county: e.target.value,
                      })
                      updateEmergencyContactAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Country:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editEmergencyContactAddress.country || ''}
                    onChange={(e) => {
                      const address = editEmergencyContactAddress
                      address.country = e.target.value
                      setEditEmergencyContactAddress({
                        ...editEmergencyContactAddress,
                        country: e.target.value,
                      })
                      updateEmergencyContactAddress(address)
                    }}
                  />
                </div>
                <div className="mb-2 flex flex-col md:flex-row">
                  <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                    Postcode:
                  </span>
                  <input
                    type="text"
                    className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                    value={editEmergencyContactAddress.postcode || ''}
                    onChange={(e) => {
                      const address = editEmergencyContactAddress
                      address.postcode = e.target.value
                      setEditEmergencyContactAddress({
                        ...editEmergencyContactAddress,
                        postcode: e.target.value,
                      })
                      updateEmergencyContactAddress(address)
                    }}
                  />
                </div>
              </div>
            )}

            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Contact Number:
              </span>
              {editMode ? (
                <input
                  type="text"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                  defaultValue={editEmergencyContact.contact_number}
                  onChange={(e) =>
                    handleEmergencyContactChange('contact_number', e)
                  }
                />
              ) : (
                <span>
                  {
                    JSON.parse(clientData.client_details.emergency_contact)
                      .contact_number
                  }
                </span>
              )}
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Relationship to Client:
              </span>
              {editMode ? (
                <input
                  type="text"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                  defaultValue={editEmergencyContact.relationship}
                  onChange={(e) =>
                    handleEmergencyContactChange('relationship', e)
                  }
                />
              ) : (
                <span>
                  {' '}
                  {
                    JSON.parse(clientData.client_details.emergency_contact)
                      .relationship
                  }
                </span>
              )}
            </div>

            <hr className="mt-4 pb-4" />
            <h2 className="font-bold text-xl mb-2">Contact Preferences</h2>
            <div className="">
              <p className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Preferred Method of Contact:
              </p>
            </div>
            <div className="mb-2 flex flex-col md:flex-row items-center space-x-4">
              <div>
                {editMode ? (
                  <div className="flex">
                    {contactMethods?.map((method) => {
                      return (
                        <div key={method.id} className="items-center flex">
                          <input
                            type="checkbox"
                            id="contact_method"
                            value={method.title}
                            defaultChecked={
                              Array.isArray(editContactMethod) &&
                              editContactMethod
                                .map((e) => e.title)
                                .includes(method.title)
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                            onChange={() => handleContactMethod(method)}
                          />
                          <label
                            htmlFor="clinic_details"
                            className="ml-2 mr-4 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {method.title}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div>
                    {clientData.contact_methods.length > 0 && (
                      <div className="flex space-x-4">
                        {clientData.contact_methods.map((method) => {
                          return <div key={method.id}>{method.title}</div>
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Preferred Contact Time:
              </span>
              {editMode ? (
                <input
                  type="text"
                  className="border border-slate-400 rounded-lg px-2 py-1 text-sm"
                  defaultValue={editClientDetails.contact_time}
                  onChange={(e) => handleDetailsChange('contact_time', e)}
                />
              ) : (
                <span>{clientData.client_details.contact_time}</span>
              )}
            </div>
            <div className="mb-2 flex flex-col md:flex-row">
              <span className="text-wba-primary font-semibold mr-2 min-w-[200px]">
                Send automatic notifications:
              </span>
              {editMode ? (
                <div className="flex items-center">
                  <span className="pr-2 text-gray-700 text-sm">No</span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 data-[checked]:bg-blue-500"
                  >
                    <span className="sr-only">Use setting</span>
                    <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                      >
                        <svg fill="none" viewBox="0 0 12 12" className="h-3 w-3 text-gray-400">
                          <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <span
                        aria-hidden="true"
                        className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                      >
                        <svg fill="currentColor" viewBox="0 0 12 12" className="h-3 w-3 text-indigo-600">
                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                      </span>
                    </span>
                  </Switch>
                  <span className="pl-2 text-gray-700">Yes</span>
                </div>
              ) : (
                <div className="flex items-center">
                <div className="flex items-center">
                  {enabled ? (<span className="pl-2 text-gray-700">Yes</span>) : ( <span className="pr-2 text-gray-700">No</span>) }
                </div>
                </div>
              )}
            </div>
            <div className="flex justify-end">
              {editMode && (
                <button
                  onClick={handleSaveClick}
                  className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700"
                >
                  <FontAwesomeIcon icon={faUpload} />
                  <span className="pl-1">Save</span>
                </button>
              )}
            </div>
          </section>
        </section>
      )}
    </div>
  )
}

export default Client
