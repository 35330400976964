import { Link } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import moment from'moment'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
 faWarning
} from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'

function ClientNav({ active = null, client = {}, audit = {}, type = null }) {
  const { admin, can, auth } = useAuth()
  const axios = useAxiosPrivate()
  const navigate = useNavigate()
  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY  hh:mma');
  };

  const fetchUserAccessRevoked = async () => {
    if(client.id && auth.user.type_id >= 3) {
      try {
        const response = await axios.post('/users/check-user-access', { userId: auth.user.id, clientId: client.id });
        if (response.data.result.accessRevoked) {
            // alert('Your access has been revoked.');
            navigate(`/`)
        }
      } catch (error) {
          console.error('Error checking user access:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserAccessRevoked();
    if (process.env.REACT_APP_ENV !== 'local') {
      // Check user access every minute
      const userAccessCheckInterval = setInterval(async () => {
        await fetchUserAccessRevoked();
      }, 60000);

      return () => {
        clearInterval(userAccessCheckInterval)
      }
    }
  }, [client])

  return (
    <div className='mb-4'>
      <div className="bg-white rounded-md shadow-md py-3 px-4 overflow-x-auto">
          <ul className="flex px-0">
            <Link to={`/clients/${client.id}`} state={client}>
              <li className={`tab-btn ${active === 'details' ? 'active' : ''}`}>
                Details
              </li>
            </Link>
            {admin && (
              <Link to={`/clients/${client.id}/insurer`} state={client}>
                <li className={`tab-btn ${active === 'insurer' ? 'active' : ''}`}>
                  Insurer
                </li>
              </Link>
            )}
            <Link to={`/clients/${client.id}/claims`} state={client}>
              <li
                className={`tab-btn ${
                  active === 'claims' ||
                  window.location.href.indexOf('claims') !== -1
                    ? 'active'
                    : ''
                }`}
              >
                Claims
              </li>
            </Link>
            {/* <Link to={`/clients/${client.id}/calendar`}>
              <li className={`tab-btn ${active === 'calendar' ? 'active' : ''}`}>
                Booking Calendar
              </li>
            </Link> */}
            <Link to={`/clients/${client.id}/messages`} state={client}>
              <li className={`tab-btn ${active === 'messages' ? 'active' : ''}`}>
                Messages
              </li>
            </Link>
            <Link to={`/clients/${client.id}/notes`} state={client}>
              <li className={`tab-btn ${active === 'notes' ? 'active' : ''}`}>
                Notes
              </li>
            </Link>
            <Link to={`/clients/${client.id}/account-settings`} state={client}>
              <li
                className={`tab-btn ${
                  active === 'account-settings' ? 'active' : ''
                }`}
              >
                Account Settings
              </li>
            </Link>
            <Link to={`/clients/${client.id}/shared-documents`} state={client}>
              <li
                className={`tab-btn ${
                  active === 'shared-documents' ? 'active' : ''
                }`}
              >
                Shared Documents
              </li>
            </Link>
            {(type && can('see audits')) && (
              <Link to={`/clients/${client.id}/audits/${audit}/${type}`} state={client}>
                <li
                  className={`tab-btn ${active === 'audits' ? 'active' : ''}`}
                >
                  {type} Audit Log
                </li>
              </Link>
            )}
          </ul>
        </div>
        {client.assigned_practitioner && client.assigned_practitioner.length > 0 && client.assigned_practitioner[0] && client.assigned_practitioner[0].pivot.expiration !== null && (
          auth.user.id === client.assigned_practitioner[0].id && new Date(client.assigned_practitioner[0].pivot.expiration) >= new Date() && (
            <div className='bg-rose-200 border border-rose-700 rounded-lg px-4 py-2 mt-4 text-rose-800 font-medium'>
              <FontAwesomeIcon className='text-rose-700 h-5 w-5 pr-3' icon={faWarning} /> 
              <span>Your access to this client will expire on {formatDate(client.assigned_practitioner[0].pivot.expiration)}</span>
            </div>
          )
        )}
    </div>
  )
}

export default ClientNav
