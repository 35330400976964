import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faBell,
  faUser,
  faRightFromBracket,
  faTimes,
  faUsers,
  faHouse,
} from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useLogout from '../hooks/useLogout'

function MobileHeader() {
  const { auth, can } = useAuth()
  const axios = useAxiosPrivate()
  const logout = useLogout()
  const navigate = useNavigate()

  const [menuOpen, setMenuOpen] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const navClasses =
  'w-full text-white py-3 px-3 flex relative rounded-md items-center'

  useEffect(() => {
    fetchNotifications()
  }, [])

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('/notifications/user/0')
      if (response.data.result.length > 0) {
        setUnreadNotifications(true)
      }
      setNotifications(response.data.result)
    } catch (error) {}
  }

  const clearNotification = async (selected) => {
    try {
      await axios.post(`/notifications/${selected.id}/clear/user/${auth.user.id}`)
      setNotifications(notifications.filter(n => n.id !== selected.id))
    } catch (error) {}
  }

  const runSearch = async () => {
    try {
      const response = await axios.get(`/search/clients/${searchQuery}`)
      if (!response.data.error) {
        setSearchResults(response.data.result)
      }
    } catch (error) {}
  }

  const viewClient = (id) => {
    setSearchQuery('')
    setSearchResults([])
    navigate(`/clients/${id}`)
  }

  useEffect(() => {
    setSearchResults([])
    if (searchQuery.length >= 3) runSearch()
  }, [searchQuery])

  return (
    <header className="bg-white w-full shadow-md p-4 flex justify-between items-center fixed z-50 rounded-l-xl">
      <button onClick={() => setMenuOpen(!menuOpen)} className="text-gray-700">
        <FontAwesomeIcon icon={faBars} className="text-2xl" />
      </button>
      <span className="font-bold  mr-10">Welcome, {auth?.user?.name}</span>

      {menuOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40" onClick={() => setMenuOpen(false)}>
          <div className="bg-white w-72 h-full p-4 shadow-lg relative overflow-y-auto" onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-4 right-4 text-gray-500"
              onClick={() => setMenuOpen(false)}
            >
              <FontAwesomeIcon icon={faTimes} className="text-lg" />
            </button>
            <div className="mt-10 space-y-4">
              {/* Search Section */}
              <div>
                <div className="text-sm font-semibold mb-2">Search Clients</div>
                <div className="flex items-center gap-2 relative">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    className="w-full px-3 py-1.5 border border-gray-300 rounded-md text-sm pr-10"
                  />
                  {searchQuery && (
                    <button
                      className="absolute right-2 text-gray-400 hover:text-gray-600"
                      onClick={() => setSearchQuery('')}
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-sm" />
                    </button>
                  )}
                </div>
                {searchResults.length > 0 && (
                  <ul className="mt-2 max-h-40 overflow-y-auto border border-gray-100 rounded-md">
                    {searchResults.map((client) => (
                      <li
                        key={client.id}
                        onClick={() => viewClient(client.id)}
                        className="px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer border-b"
                      >
                        {client.name} <span className="text-gray-500 ml-2 text-xs">{client.reference}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div>
                <div className="pt-2">
                  {can('access dashboard') ? (
                    <Link
                      to="/"
                      onClick={() => setMenuOpen(false)}
                      className={({ isActive }) =>
                        isActive
                          ? 'bg-blue-500 ' + navClasses + ' pointer-events-none'
                          : navClasses + ' hover:bg-slate-700'
                      }
                    >
                      <FontAwesomeIcon icon={faHouse} className="mr-3" />
                      <span>Home</span>
                    </Link>
                  ) : null}
                </div>
              </div>
              <div>
                <Link
                  to="/notifications"
                  onClick={() => setMenuOpen(false)}
                  className={({ isActive }) =>
                    isActive
                      ? "bg-blue-500 " + navClasses + " pointer-events-none"
                      : navClasses + " hover:bg-slate-700"
                  }
                >
                  <FontAwesomeIcon icon={faBell} className="mr-4" />
                  <span>Notifications</span>
                </Link>
              </div>
              <div>
                {can('access clients') ? (
                  <Link
                      to="/clients"
                      onClick={() => setMenuOpen(false)}
                      className={({ isActive }) =>
                        isActive
                          ? `bg-blue-500 text-white ${navClasses}`
                          : `${navClasses} hover:bg-slate-100`
                      }
                    >
                      <FontAwesomeIcon icon={faUsers} className="mr-2" /> Clients
                  </Link>
                ) : null}
              </div>
              <div className="pt-4 border-t border-gray-200">
                <Link to={`/users/${auth.user.id}`} className={({ isActive }) =>
                    isActive
                      ? `bg-blue-500 text-white ${navClasses}`
                      : `${navClasses} hover:bg-slate-100 `
                  }>
                  <FontAwesomeIcon icon={faUser} className="mr-2" /> My Profile
                </Link>
                <button
                  onClick={logout}
                  className="block font-medium text-red-600 mt-2"
                >
                  <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" /> Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default MobileHeader