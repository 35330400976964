import moment from 'moment'
import { useState, useEffect } from 'react'
import Pagination from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Modal from './Modal'

function BulkPayments({ data = [] }) {
  const { createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const [payslipData, setPayslipData] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [dispatchSize, setDispatchSize] = useState(8)
  const [totalDispatches, setTotalDispatches] = useState(0)
  const [sendingPayslips, setSendingPayslips] = useState(false)
  const [currentBatchNumber, setCurrentBatchNumber] = useState(0)
  const [failedDispatchItems, setFailedDispatchItems] = useState([])

  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    if (data) setPayslipData(data)
  }, [data])

  // Handle row checkbox change
  const handleRowCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }

  // Handle header checkbox change
  const handleHeaderCheckboxChange = () => {
    if (selectedRows.length === payslipData.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(payslipData.map((item) => item.id))
    }
  }

  const handleEmailButtonClick = async () => {
    createInfo('info', 'Practitioner payslips processing...')

    const selectedData = payslipData.filter((item) =>
      selectedRows.includes(item.id)
    )
    const selectedDataLength = selectedData.length

    const numberOfDispatches = Math.ceil(selectedDataLength / dispatchSize)
    setTotalDispatches(numberOfDispatches)
    let failedDispatches = [];

    try {
      setSendingPayslips(true)
      for (let i = 0; i < numberOfDispatches; i++) {
        setCurrentBatchNumber(i + 1)
        const start = i * dispatchSize;
        const end = start + dispatchSize;
        const batch = selectedData.slice(start, end);

        await axios.post('/payslips/email-payslip', batch).then((res) => {
            if (res.data.message !== 'Payslips Sends Queued') {
              batch.forEach((item) => {
                failedDispatches.push(item);
              });
            }
        });
      }
    } catch (error) {
      console.error('Error sending emails:', error)
    } finally {
      setSelectedRows([])
      setSendingPayslips(false)
      setFailedDispatchItems(failedDispatches);
      if(failedDispatches.length > 0) {
        setModalTitle('Failed Dispatches')
      } else {
        setModalTitle('Sent Successfully')
      }
      setShowModal(true)
    }
  }

  const SuccessfulDispatchElement = () => {
    return (
      <div>
      <p className="text-green-600 font-semibold mb-4">
        All payslips have been sent successfully!
      </p>
      <div className="mt-4 flex justify-end">
        <button
        onClick={() => setShowModal(false)}
        className="rounded-lg bg-gray-500 text-white px-4 py-2 hover:bg-gray-600"
        >
        Close
        </button>
      </div>
      </div>
    )
  }

  const FailedDispatchElement = () => {
    const copyToClipboard = () => {
      const names = failedDispatchItems.map((item) => item.name).join(', ');
      navigator.clipboard.writeText(names).then(() => {
      createInfo('success', 'Names copied to clipboard!');
      }).catch((err) => {
      console.error('Failed to copy text: ', err);
      createInfo('error', 'Failed to copy names to clipboard.');
      });
    };

    return (
      <div>
        <p className="text-red-600 font-semibold mb-4">
          Some dispatches were unsuccessful. Below are the items from those dispatches:
        </p>
        <div className="max-h-64 overflow-y-auto border border-gray-300 rounded-lg p-4 bg-gray-50">
          <table>
          <thead>
            <tr>
            <th className="px-4 py-2">Counsellor Name</th>
            </tr>
          </thead>
          <tbody>
            {failedDispatchItems.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="px-4 py-2">
              <p className="text-sm text-gray-700">
                {item.name}
              </p>
              </td>
            </tr>
            ))}
          </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-between">
          <button onClick={copyToClipboard}
            className="rounded-lg bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
          >
            Copy Names to Clipboard
          </button>
          <button onClick={() => setShowModal(false)}
            className="rounded-lg bg-gray-500 text-white px-4 py-2 hover:bg-gray-600"
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  const ModalBody = () => {
    if(failedDispatchItems.length > 0) {
      return <FailedDispatchElement />
    } else {
      setModalTitle('Bulk Payments Complete')
      return <SuccessfulDispatchElement />
    }
  }

  const resetModal = () => {
    setShowModal(false)
    setFailedDispatchItems([])
    setModalTitle('')
    setTotalDispatches(0)
    setCurrentBatchNumber(0)
  }

  return (
    <div className="w-full py-4">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold">Bulk Payments Overview</div>
        <div className="flex items-center justify-end space-x-2">
          {selectedRows.length > 0 && !sendingPayslips && (
            <button
              onClick={handleEmailButtonClick}
              className="rounded-lg bg-wba-primary text-white px-4 py-2 hover:bg-wba-primary-hover text-base"
            >
              <FontAwesomeIcon icon={faPaperPlane} className="pr-2" />
              Send Payslips
            </button>
          )}
          {sendingPayslips && (
            <div className="flex items-center">
              <span className="ml-2">
                Sending Payslip Batch: {currentBatchNumber} of {totalDispatches}
              </span>
              <FontAwesomeIcon className="mx-4" icon={faSpinner} spin />
            </div>
          )}
        </div>
      </div>
      <div className="overflow-hidden rounded-lg mt-4">
        <table className="table-main">
          <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
            <tr>
              <th scope="col" className="px-6 py-4">
                <input
                  type="checkbox"
                  checked={selectedRows.length === payslipData.length}
                  onChange={handleHeaderCheckboxChange}
                  className=""
                />
              </th>
              <th scope="col" className="px-6 py-4">
                Counsellor Name
              </th>
              <th scope="col" className="px-6 py-4">
                Hours Worked
              </th>
              <th scope="col" className="px-6 py-4">
                Adjustments
              </th>
              <th scope="col" className="px-6 py-4">
                Sessions (total in £)
              </th>
              <th scope="col" className="px-6 py-4">
                Total (sessions + adjustments)
              </th>
            </tr>
          </thead>
          <tbody>
            {payslipData.length > 0 ? (
              payslipData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600"
                  >
                    <td className="px-6 py-4 w-10">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleRowCheckboxChange(item.id)}
                        className=""
                      />
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.name}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.totalDuration}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      £{item.adjustmentTotal}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      £{item.totalSessions}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      £{(item.adjustmentTotal + item.totalSessions).toFixed(2)}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <p className="no-records">No Sessions found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BulkPayments
