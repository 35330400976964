import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from './Modal'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function MainTitle({ title, client = null, claim = null, backButton = false, child = false, discharge = false, waiting = false}) {
  const navigate = useNavigate()
  const { setShowLoader, createInfo} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [showDischargeModal, setShowDischargeModal] = useState(false)
  const [showClinicModal, setShowClinicModal] = useState(false)
  const [showAdminModal, setShowAdminModal] = useState(false)
  const [showWaitingModal, setShowWaitingModal] = useState(false)
  const [modalTitle, setModalTitle] = useState({})
  const [dischargeData, setDischargeData] = useState({})
  const [clinicData, setClinicData] = useState({})
  const axios = useAxiosPrivate()
  const [isOpen, setIsOpen] = useState(false);
  const isOnList = client?.is_on_list || client?.IsOnList;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const goBack = () => {
    navigate(-1)
  }

  const openDischargeReason = (title) => {
    setModalTitle(`Set Discharge Reason for ${title}`)
    setShowDischargeModal(true)
    setShowModal(true)
  }

  const referToClinic = (title) => {
    setModalTitle(`Refer ${title} to Clinic`)
    setShowClinicModal(true)
    setShowModal(true)
  }

  const referToAdmin = () => {
    setModalTitle(`Refer ${title} to Admin`)
    setShowAdminModal(true)
    setShowModal(true)
  }

  const openWaitingList = (title) => {
    setModalTitle(`Add ${title} to waiting room`)
    setShowWaitingModal(true)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (showDischargeModal) {
      return <DischargeReasonElement />
    }
    if (showClinicModal) {
      return <ReferToClinicElement />
    }
    if (showAdminModal) {
      return <ReferToAdminElement />
    }
    if (showWaitingModal) {
      return <WaitingListElement />
    }
    return null
  }

  const WaitingListElement = () => {
    const submitWaitingListReason = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.post(
          '/notifications/create',
          {
            title: 'Waiting List',
            body: 'Client ' + client.name + ' requests to be added to the waiting list',
            meta: {
              client_name: client.name,
              client_assessment_date: client.date_of_assessment,
              availability: client.client_details.contact_time,
              claim_risk: client.risk[0].title,
              client_id: client.id,
              client_reference: client.reference,
              claim_reference: client.claim_reference,
            },
            type: 'Waiting',
            area: 'Client',
          }
        )
        createInfo('success', `Client waiting list request sent successfully`)
        setShowLoader(false)
        window.location.reload();
      } catch (error) {
        createInfo('error', `Could not complete client waiting list request`)
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={submitWaitingListReason}>
        <div className="mb-3">
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Name <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue={client.name}
              onChange={(e) => (client.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Date of Assessment <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="date"
              name="reason"
              id="reason"
              defaultValue=""
              onChange={(e) => (client.date_of_assessment = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Availability<span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue={client.client_details.contact_time}
              onChange={(e) => (client.client_details.contact_time = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Risk<span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue={client.risk[0].title}
              onChange={(e) => (client.risk[0].title = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
          <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Save</button>
            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const DischargeReasonElement = () => {
    const submitDischargeReason = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.post(
          '/notifications/create',
          {
            title: 'Request to Discharge',
            body: dischargeData.reason,
            meta: {
              client_id: client.id,
              client_reference: client.reference,
              claim_reference: claim?.reference ?? '',
            },
            type: 'Admin',
            area: 'Client',
          }
        )
        createInfo('success', `Client discharge request sent successfully`)
        setShowLoader(false)
      } catch (error) {
        createInfo('error', `Could not complete client discharge request`)
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={submitDischargeReason}>
        <div className="mb-3">
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Reason <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue={dischargeData.reason}
              onChange={(e) => (dischargeData.reason = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
          <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Save</button>
            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ReferToClinicElement = () => {
    const submitClinicReason = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.post(
          '/notifications/create',
          {
            title: 'Refer to Clinical Team',
            body: clinicData.reason,
            meta: {
              client_id: client.id,
              client_reference: client.reference ?? '',
              claim_reference: claim?.reference ?? '',
            },
            type: 'Clinic Referral',
            area: 'Client',
          }
        )
        createInfo('success', `Client referal request sent successfully`)
        setShowLoader(false)
      } catch (error) {
        alert(error.message)
        createInfo('error', `Could not complete client referal request ${error.message}`)
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={submitClinicReason}>
        <div className="mb-3">
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Reason <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue={clinicData.reason}
              onChange={(e) => (clinicData.reason = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
          <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Save</button>
            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ReferToAdminElement = () => {
    const submitClinicReason = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.post(
          '/notifications/create',
          {
            title: 'Refer to Admin',
            body: clinicData.reason,
            meta: {
              client_id: client.id,
              client_reference: client.reference,
              claim_reference: claim?.reference ?? '',
            },
            type: 'Admin Referral',
            area: 'Client',
          }
        )
        createInfo('success', `Client discharge request sent successfully`)
        setShowLoader(false)
      } catch (error) {
        createInfo('error', `Could not complete client discharge request`)
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={submitClinicReason}>
        <div className="mb-3">
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Reason <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue={clinicData.reason}
              onChange={(e) => (clinicData.reason = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
          <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Save</button>
            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetNotes = true) => {
    setDischargeData({reason: ''})
    setClinicData({reason: ''})
    setShowModal(false)
    setShowWaitingModal(false)
    setShowDischargeModal(false)
    setShowClinicModal(false)
    setShowAdminModal(false)
  }

  return (
    <div className="font-bold py-2 mt-2 text-xl w-full border-b border-b-gray-300 mb-4">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <div className='flex item justify-between'>
        <h1 className='flex flex-col md:flex-row md:items-center space-x-2'>
          <span>{title}</span> 
          {client?.status && (
              <span className={`w-1/4 status ${client.status.replace(/\s+/g, '-').toLowerCase()}`}>
                {client.status}
              </span>
          )}
          {(child === true || child === 1) ? (
            <span className='text-xs font-medium px-4 py-1.5 bg-pink-600 rounded-full text-white'>Child</span>
          ) : null}
        </h1>
        <div className="relative inline-block text-left">
          {window.location.pathname.includes('/clients/') && (
            <div>
              <button
                onClick={toggleDropdown}
                className="inline-flex justify-center w-full rounded-lg border border-gray-300 shadow-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none"
              >
                Actions
              </button>
            </div>
          )}

          {isOpen && (
            <div className="absolute z-10 mt-2 w-56 -left-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1 space-y-1">
                {waiting && (
                  <button
                    onClick={() => {
                      if (!isOnList) openWaitingList(title);
                      setIsOpen(false);
                    }}
                    disabled={isOnList}
                    className={`w-full text-left px-4 py-2 text-sm ${
                      isOnList
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'text-blue-700 hover:bg-blue-100 rounded-lg'
                    }`}
                  >
                    {isOnList ? 'On Waiting List' : 'Add to Waiting List'}
                  </button>
                )}

                {discharge && (
                  client?.status_id !== 9 && client?.status_id !== 4 ? (
                    <button
                      onClick={() => {
                        openDischargeReason(title);
                        setIsOpen(false);
                      }}
                      className="w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-red-100 rounded-lg"
                    >
                      Request Discharge
                    </button>
                  ) : (
                    <div className="w-full px-4 py-2 text-sm text-gray-500 cursor-not-allowed">
                      Client has been discharged
                    </div>
                  )
                )}

                <button
                  onClick={() => {
                    referToClinic(title);
                    setIsOpen(false);
                  }}
                  className="w-full text-left px-4 py-2 text-sm text-green-700 hover:bg-green-100 rounded-lg"
                >
                  Refer to Clinical Team
                </button>

                <button
                  onClick={() => {
                    referToAdmin(title);
                    setIsOpen(false);
                  }}
                  className="w-full text-left px-4 py-2 text-sm text-purple-700 hover:bg-purple-100 rounded-lg"
                >
                  Refer to Admin
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {backButton && (
        <span
          className="inline-flex items-center text-slate-500 mt-1 cursor-pointer hover:text-slate-600"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
          <span className="text-sm ml-2">Go Back</span>
        </span>
      )}
    </div>
  )
}

export default MainTitle
