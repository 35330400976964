import React from 'react'
import moment from 'moment'
import { Fragment } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const ReferralsSlideOut = ({ open, setOpen, notificationData, viewClient, replies }) => {
  const formatDate = (date) => date ? moment(date).format('DD/MM/YYYY') : '-';

  const relatedReplies = replies?.filter(
    r => r.meta?.original_notification_id === notificationData?.id
  );

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative" onClose={setOpen} style={{ zIndex: 999 }}>
        <Transition
          as={Fragment}
          show={open}
          enter="ease-in-out duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition
                as={Fragment}
                show={open}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-blue-500 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-white">
                          {notificationData?.title || 'Notification Details'}
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-blue-500 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="p-6 text-sm text-gray-700">
                      <div className="mb-4">
                        <div className="font-medium">Description:</div>
                        <p className="text-slate-600 whitespace-pre-line">{notificationData?.body}</p>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                          <div className="font-medium">Client Reference:</div>
                          <div className="text-slate-600">{notificationData?.meta?.client_reference}</div>
                        </div>
                        <div>
                          <div className="font-medium">Claim Reference:</div>
                          <div className="text-slate-600">{notificationData?.meta?.claim_reference}</div>
                        </div>
                        <div>
                          <div className="font-medium">Request Date:</div>
                          <div className="text-slate-600">{formatDate(notificationData?.meta?.requested_at)}</div>
                        </div>
                        <div>
                          <div className="font-medium">Requested By:</div>
                          <div className="text-slate-600">{notificationData?.created}</div>
                        </div>

                        {notificationData?.meta?.session_id && (
                          <>
                            <div className="col-span-1">
                              <div className="font-medium">Session ID:</div>
                              <div className="text-slate-600">{notificationData.meta.session_id}</div>
                            </div>
                            <div className="col-span-1">
                              <div className="font-medium">Session Date Created:</div>
                              <div className="text-slate-600">{formatDate(notificationData.meta.session_date)}</div>
                            </div>
                          </>
                        )}
                      </div>

                      {relatedReplies?.length > 0 && (
                        <div className="mt-8 border-t pt-4">
                          <div className="text-sm font-semibold text-gray-700 mb-2">Previous Replies:</div>
                          <div className="space-y-4">
                            {relatedReplies.map((reply, index) => (
                              <div key={index} className="p-4 border rounded-md bg-gray-50">
                                <div className="text-slate-700 whitespace-pre-line">{reply.body}</div>

                                <div className="text-xs text-gray-500 mt-2">
                                  From: <span className="font-medium">{reply.meta?.original_user_name || 'Unknown User'}</span><br />
                                  Sent: {moment(reply.created_at).format('DD/MM/YYYY [at] HH:mm')}
                                </div>
                              </div>
                            ))}   
                          </div>
                        </div>
                      )}

                      <div className="flex gap-4 mt-6">
                        <button
                          onClick={() =>
                            notificationData.meta?.session_id
                              ? viewClient(notificationData.meta?.client_id, notificationData.meta?.claim_id, notificationData.meta?.session_id)
                              : viewClient(notificationData.meta?.client_id)
                          }
                          className="btn w-1/2"
                        >
                          {notificationData.meta?.session_id ? 'View Session' : 'View Client'}
                        </button>
                        <button
                          type="button"
                          className="btn red w-1/2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ReferralsSlideOut
